@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
body {
  font-family: 'Poppins', sans-serif;
}

/* Header */

header {
  padding: 20px 0px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 10px 30px 0 rgba(162,162,162,.82);
}

.logo img {
  max-width: 250px;
}

/* Menu */

.menu a {
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px 10px;
  padding: 10px 15px;
}

.menu a:hover {
  color: #cc011e;
}

a.buy_now.nav-link {
  background-color: #3d443d;
  border-radius: 5px;
  color: #fff;
}

a.buy_now.nav-link:hover {
  background-color: #cc011e;
}


/* Hero */

#hero {
  background-image: linear-gradient(180deg,rgba(236,234,253,.8) 50%,rgba(236,234,253,.05));
  padding: 50px 0px;
}

#hero img {
  width: 100%;
  height: 100%;
}

.hero_content {
  margin-top: 50px;
}

.hero_content h1 {
  font-size: 3rem;
}

.hero_content span {
  color: #3d443d;
  font-size: 0.9rem;
  font-style: italic;
}

/* Featured */

.featured_block {
  display: block;
  text-align: center;
  padding: 50px 25px;
}

.featured_block svg {
  background-color: #f0eefd;
  border-radius: 50%;
  font-size: 3.2rem;
  padding: 20px;
  min-width: 55px;
}

.featured_block:hover svg {
  background-color: #cc011e;
  color: #fff;
}

.featured_block span {
  font-size: 1.5rem;
  font-weight: 700;
}

.featured_block:hover {
  border: 1px solid #f1f1f1;
  box-shadow: 0 10px 30px 0 rgba(162, 162, 162, 0.157);
}

hr.divider {
  width: 100%;
  height: 1px;
  background-color: transparent;
  background-image: linear-gradient(90deg,rgba(206,211,246,0),#bbb 38%,#bbb 64%,rgba(206,211,246,0) 99%);
  opacity: .4;
  border: none;
  margin: 30px 0px;
}

/* About */

#despre {
  padding: 50px 0px;
}

#despre img {
  width: 100%;
  height: 100%;
}

#despre h2 {
  font-size: 3rem;
}

#despre span {
  color: #cc011e;
}

#despre h2:after {
  background-color: #cc011e;
  content: '';
  display: block;
  margin: 15px 0px 30px 0px;
  position: relative;
  width: 145px;
  height: 2px;
}

/* Caracteristici */

#caracteristici {
  background-color: #cc011e;
  color: #fff;
  padding: 50px 0px;
}

#caracteristici h3, #caracteristici p {
  text-align: center;
}

#caracteristici h3 {
  margin-bottom: 40px;
}

/* Avantaje */

#avantaje {
  padding: 50px 0px;
}

#avantaje img {
  margin-top: 180px;
  width: 100%;
  height: 100%;
}

#avantaje h4 {
  font-size: 2.3rem;
}

#avantaje h4:after {
  background-color: #cc011e;
  content: '';
  display: block;
  margin: 15px 0px 30px 0px;
  position: relative;
  width: 145px;
  height: 2px;
}

/* CTA */

.cta {
  background-color: #3d443d;
  color: #fff;
  text-align: center;
  padding: 65px 0px;
}

.cta h5 {
  font-size: 3rem;
}

.cta button {
  color: #fff;
  border-color: #fff;
}

/* Contact */

#contact {
  padding: 50px 0px;
}

#contact h6 {
  color: #cc011e;
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 25px;
}

.social_links ul {
  padding: 0;
}

.social_links li {
  display: inline;
}

.social_links a {
  color: #cc011e;
  background-color: #f0eefd;
  border-radius: 50%;
  display: inline-block;
  width: 45px;
  margin: 5px;
  text-decoration: none;
  position: relative;
  padding: 10px;
  text-align: center;
}

.social_links a:hover {
  color: #fff;
  background-color: #cc011e; 
}

/* Copyright */

#copyright {
  background-image: linear-gradient(180deg,rgba(236,234,253,.8) 50%,rgba(236,234,253,.05));
  padding: 20px 0px;
}

#copyright li {
  list-style-type: none;
  margin: 15px 0px;
}

.development {
  text-align: right;
}

.development img {
  margin-left: 10px;
}

ul.footer_links {
  margin: 0px auto 20px;
  padding: 0;
}

.footer_links li {
  display: inline;
  padding: 10px;
}

.footer_links a {
  color: #111;
  text-decoration: none;
}

ul.legal_links {
  margin: 0;
  padding: 0;
}

.legal_links li {
  display: inline;
  list-style-type: none;
}

.legal_links img {
  margin-right: 10px;
}

.CookieConsent a {
  color: #fff;
}

@media only screen and (max-width: 768px)  {
  button.navbar-toggler.collapsed {
    border: 0;
  }
  .hero_content, .offcanvas-body, #despre, #caracteristici, #avantaje, #contact, .development, #copyright {
    text-align: center;
  }
  #despre h2:after {
    margin: 15px auto;
  }
  #avantaje img {
    margin-top: 10px;
  }
  #avantaje h4:after {
    margin: 15px auto;
  }
  .copyright li {
    display: block;
  }
  .development {
    margin: 30px 0px;
  }
}